// https://stripe.com/docs/checkout#integration-custom
/* global Stripe */

function bindCheckoutSession() {
  const stripeCheckoutSession = document.querySelector(
    '.js-stripe-checkout-session',
  );

  if (!stripeCheckoutSession) {
    return;
  }

  const stripePublishableKey = stripeCheckoutSession.getAttribute('data-key');
  const stripeCheckoutSessionId = stripeCheckoutSession.getAttribute(
    'data-session-id',
  );

  // https://stripe.com/docs/payments/checkout/server#add
  stripeCheckoutSession.addEventListener('click', () => {
    const stripe = Stripe(stripePublishableKey);
    stripe.redirectToCheckout({
      sessionId: stripeCheckoutSessionId,
    });
  });
}

const PaymentApi = {
  bindCheckoutSession,
};

export default PaymentApi;
