// https://github.com/tomchentw/react-google-maps/issues/434
/* global google */

function Init(inputId) {
  this.input = document.getElementById(inputId);

  if (this.input) {
    this.locationId = this.input.getAttribute('data-locationfieldid');
    this.locationLabelId = this.input.getAttribute('data-locationlabelid');
    this.latId = this.input.getAttribute('data-latfieldid');
    this.lngId = this.input.getAttribute('data-lngfieldid');
  }
}

Init.prototype = {
  enable() {
    if (!this.input) {
      return;
    }

    const options = {
      componentRestrictions: { country: 'gb' },
    };
    const autocomplete = new google.maps.places.Autocomplete(
      this.input,
      options,
    );

    // data returned on selecting a Place
    autocomplete.setFields([
      'geometry',
      'formatted_address',
      'type',
      'name',
    ]);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let location = place.formatted_address;
      if (place.types.includes('establishment')) {
        location = `${place.name}, ${place.formatted_address}`;
      }

      document.getElementById(this.locationId).value = location;
      if (this.locationLabelId) {
        document.getElementById(this.locationLabelId).textContent = location;
      }
      document.getElementById(this.latId).value = place.geometry.location.lat();
      document.getElementById(this.lngId).value = place.geometry.location.lng();
    });

    if (document.querySelector('.js-clear-location')) {
      const clearHandler = function () {
        this.input.value = '';
        document.getElementById(this.locationId).value = '';
      }.bind(this);

      document.querySelector('.js-clear-location').addEventListener('click', clearHandler);
    }
  },
};

function bindGoogleMapsPlacesAutocomplete(inputId) {
  return new Init(inputId);
}

export default bindGoogleMapsPlacesAutocomplete;
