import Rails from '@rails/ujs';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

function insertInnerHTML(cssSelector, html) {
  document.querySelector(cssSelector).innerHTML = html;
}

function insertOuterHTML(cssSelector, html) {
  document.querySelector(cssSelector).outerHTML = html;
}

function bindOnChangeClick() {
  const triggers = document.querySelectorAll('[data-on-change-click]');
  Array.prototype.forEach.call(triggers, (trigger) => {
    trigger.addEventListener('change', () => {
      const target = trigger.getAttribute('data-on-change-click');
      document.getElementById(target).click();
    });
  });
}

function bindOnClickClick() {
  const triggers = document.querySelectorAll('[data-on-click-click]');
  Array.prototype.forEach.call(triggers, (trigger) => {
    trigger.addEventListener('click', () => {
      const target = trigger.getAttribute('data-on-click-click');
      document.getElementById(target).click();
    });
  });
}

function bindClick(cssSelector) {
  const targets = document.querySelectorAll(cssSelector);
  Array.prototype.forEach.call(targets, (target) => {
    target.click();
  });
}

function bindOnClickScrollTo() {
  Array.prototype.forEach.call(
    document.querySelectorAll('.js-onclick-scroll-to'),
    (trigger) => {
      trigger.addEventListener('click', () => {
        const targetId = trigger.getAttribute('data-id');
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }
      });
    },
  );
}

function scrollToModalBodyTop() {
  document.getElementById(
    'js-modal-body',
  ).scrollIntoView({
    block: 'start',
    inline: 'nearest',
    behavior: 'smooth',
  });
}

function toggleElement(
  cssSelectorTrigger,
  cssSelectorTarget,
  toggleClass,
  afterToggleCallback,
) {
  const trigger = document.querySelector(cssSelectorTrigger);
  if (!trigger) {
    return;
  }

  trigger.addEventListener('click', () => {
    Array.prototype.forEach.call(
      document.querySelectorAll(cssSelectorTarget),
      (target) => {
        target.classList.toggle(toggleClass);
      },
    );

    if (afterToggleCallback) {
      afterToggleCallback();
    }
  });
}

// https://github.com/rails/rails/tree/master/actionview/app/assets/javascripts
// https://github.com/rails/rails/blob/b472feddbc0874a76028cca0bbe4ff62718d4231/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L7
// https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
function railsAjax(type, url, dataType, options) {
  Rails.ajax({
    type,
    url,
    dataType,
    ...options,
  });
}

function getElementByTextContent(cssSelector, searchText) {
  const tags = document.querySelectorAll(cssSelector);
  let found;

  for (let i = 0; i < tags.length; i += 1) {
    if (tags[i].textContent === searchText) {
      found = tags[i];
      break;
    }
  }

  return found;
}

// https://gomakethings.com/automatically-expand-a-textarea-as-the-user-types-using-vanilla-javascript/
function bindAutoExpandTextarea(cssSelector) {
  document.querySelector(cssSelector).addEventListener('input', (e) => {
    const textarea = e.currentTarget;
    // reset field height
    textarea.style.height = 'inherit';
    // get the computed styles for the textarea
    const computed = window.getComputedStyle(textarea);
    // calculate the height
    const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
      + parseInt(computed.getPropertyValue('padding-top'), 10)
      + textarea.scrollHeight
      + parseInt(computed.getPropertyValue('padding-bottom'), 10)
      + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    // little space 16px from bottom
    textarea.style.height = `${height + 16}px`;
  });
}

function bindOnCheckHide(cssSelectorTrigger, cssSelectorTarget) {
  const trigger = document.querySelector(cssSelectorTrigger);
  const target = document.querySelector(cssSelectorTarget);

  if (!trigger) {
    return;
  }

  target.classList.toggle('is-hidden', trigger.checked);

  trigger.addEventListener('click', () => {
    target.classList.toggle('is-hidden');
  });
}

const Utility = {
  insertInnerHTML,
  insertOuterHTML,
  bindOnChangeClick,
  bindOnClickClick,
  bindClick,
  bindOnClickScrollTo,
  scrollToModalBodyTop,
  toggleElement,
  railsAjax,
  getElementByTextContent,
  bindAutoExpandTextarea,
  bindOnCheckHide,
};

export default Utility;
