function bindVoteAnswer() {
  const voteAnswers = document.querySelectorAll('.js-vote-answer');
  Array.prototype.forEach.call(voteAnswers, (answer) => {
    // style mouseover/mouseleave effects
    answer.addEventListener('mouseover', (e) => {
      e.currentTarget.classList.add('is-hovered');
    });

    answer.addEventListener('mouseleave', (e) => {
      e.currentTarget.classList.remove('is-hovered');
    });

    // click to mark selection
    answer.addEventListener('click', (e) => {
      const target = e.currentTarget;
      const questionId = target.getAttribute('data-question-id');
      const voteValue = target.getAttribute('data-value');
      document.querySelector(`.js-vote-answer-value[data-question-id="${questionId}"]`).setAttribute('value', voteValue);

      const groupedVoteAnswers = document.querySelectorAll(`.js-vote-answer[data-question-id="${questionId}"]`);
      Array.prototype.forEach.call(groupedVoteAnswers, (a) => {
        a.classList.remove('is-selected');
      });

      target.classList.add('is-selected');
    });
  });
}

const Vote = {
  bindVoteAnswer,
};

export default Vote;
