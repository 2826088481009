function setCookie(cname, cvalue, exdays, cpath) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=${cpath}`;
}

export function bindAcceptCookiePrivacy() {
  if (document.querySelector('.js-agree-cookie-privacy')) {
    document
      .querySelector('.js-agree-cookie-privacy')
      .addEventListener('click', (e) => {
        e.preventDefault();
        // expire in 10 years
        setCookie('EU_COOKIE_LAW_CONSENT', 'yes', 3650, '/');
        const cookiePrivacy = document.querySelector('.js-cookie-privacy');
        cookiePrivacy.classList.add('animation-fade-out');

        // run after animation-fade-out's animation-duration
        setTimeout(() => {
          cookiePrivacy.classList.add('is-hidden');
        }, 800);
      });
  }
}

export function bindShowPopup() {
  if (document.querySelector('.js-popup-container')) {
    setTimeout(() => {
      document.querySelector('.js-popup-container a').click();
    }, 1000);
  }
}
