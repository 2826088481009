import tickAudio from '../../audios/tick.mp3';

export default class PrizeDraw {
  constructor() {
    this.winSize = 30;
    this.segmentCount = 360 / this.winSize;
    this.winColor = 'rgba(0,154,139, 1)';
    this.winAltColor = 'rgba(0,154,139, 0.5)';
    this.lostColor = 'rgba(181,181,181, 1)';
    this.lostAltColor = 'rgba(181,181,181, 0.5)';
    this.winText = 'あたり';
    this.lostText = 'はずれ';
    this.textColor = '#fff';
    this.strokeColor = '#eff0eb';
    this.lostSegments = Array(this.segmentCount).fill({
      strokeStyle: this.strokeColor, fillStyle: this.lostColor, text: this.lostText, textFillStyle: this.textColor,
    });
    this.segments = [
      {
        strokeStyle: this.strokeColor, fillStyle: this.winColor, text: this.winText, textFillStyle: this.textColor,
      },
      ...this.lostSegments,
    ];
    this.audio = new Audio(tickAudio);
    this.winCallback = null;
    this.prizeWheel = new Winwheel({
      canvasId: 'js-prize-wheel',
      numSegments: this.segmentCount,
      responsive: true,
      pointerAngle: 0,
      innerRadius: 100,
      textAlignment: 'inner',
      textOrientation: 'vertical',
      segments: this.segments,
      lineWidth: 1,
      animation: {
        type: 'spinToStop',
        duration: 10,
        spins: 20,
        easing: 'Power4.easeOut',
        callbackAfter: this.disablePrizeWheelSpinButton,
        callbackFinished: () => this.spinFinished(this),
        callbackSound: () => this.playSound(this),
        soundTrigger: 'pin',
      },
      pins: {
        number: 24,
        outerRadius: 0,
      },
    });
  }

  spinWheel(result) {
    this.updateResult(result);
    this.updateDrawLeft();
    this.resetWheel();
    this.prizeWheel.animation.stopAngle = this.stopAngle();
    this.prizeWheel.startAnimation();

    return this;
  }

  updateResult(result) {
    document.getElementById('js-prize-wheel').setAttribute('data-result', result);
  }

  updateDrawLeft() {
    document.querySelector('.js-prize-draw-left').textContent = this.drawLeft() - 1;
  }

  drawLeft() {
    return parseInt(document.querySelector('.js-prize-draw-left').textContent, 10);
  }

  resetWheel() {
    this.prizeWheel.stopAnimation(false);
    this.prizeWheel.rotationAngle = 0;
    this.resetSegmentColor();
    this.prizeWheel.draw();
  }

  resetSegmentColor() {
    this.prizeWheel.segments.forEach((segment, index) => {
      if (segment) {
        segment.fillStyle = this.segments[index - 1].fillStyle;
      }
    });
  }

  hasWon() {
    return document.getElementById('js-prize-wheel').getAttribute('data-result') === 'won';
  }

  stopAngle() {
    return this.hasWon() ? this.winStopAngle() : this.lostStopAngle();
  }

  winStopAngle() {
    return (1 + Math.floor(Math.random() * (this.winSize - 2)));
  }

  lostStopAngle() {
    return ((this.winSize + 1) + Math.floor(Math.random() * 328));
  }

  spinFinished(pd) {
    const stoppedSegmentColor = pd.hasWon() ? pd.winAltColor : pd.lostAltColor;
    TweenMax.to(pd.stoppedSegment(), 0.2, {
      fillStyle: stoppedSegmentColor,
      repeat: 4,
      yoyo: true,
      onComplete: this.enablePrizeWheelSpinButton,
      onCompleteScope: this,
    });
    TweenMax.ticker.addEventListener('tick', this.spinFinishedTicking, this);
  }

  spinFinishedTicking() {
    this.prizeWheel.draw();
  }

  disablePrizeWheelSpinButton() {
    document.querySelector('.js-prize-wheel-spin-button').setAttribute('disabled', 'disabled');
  }

  enablePrizeWheelSpinButton() {
    if (this.drawLeft() > 0 && !this.hasWon()) {
      document.querySelector('.js-prize-wheel-spin-button').removeAttribute('disabled');
    }

    if (this.hasWon()) {
      this.winCallback();
    }
  }

  stoppedSegment() {
    const segmentNumber = this.prizeWheel.getIndicatedSegmentNumber();
    return this.prizeWheel.segments[segmentNumber];
  }

  playSound(pd) {
    pd.audio.currentTime = 0;
    pd.audio.play();
  }
}
