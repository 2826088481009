// make modal active and add slide-down animation
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import tagsInput from 'tags-input';
import Sortable from 'sortablejs';
import Utility from './utility';

import GmapApi from './gmap-api';

const REMOTE_ONLY_LABEL = 'リモートのみ';
// avoid multiple ajax calls
// iOS's momentum scrolling (-webkit-overflow-scrolling: scroll) has a behaviour to call
// onscroll event 'quickly' to evaluate the scroll height, which results in
// multiple ajax calls (server seems to be hit only one though, strange...)
let gTaskLoading = false;

function showModal({ fullScreen = false } = {}) {
  document.querySelector('.js-modal').classList.add('is-active');
  const modalContent = document.querySelector('.js-modal-content');
  if (fullScreen) {
    modalContent.classList.add('is-fullscreen');
  }
  modalContent.classList.add('slide-down');
}

// add slide-up animation, make modal inactive after animation ends
function hideModal() {
  const modalContent = document.querySelector('.js-modal-content');
  modalContent.classList.remove('slide-down');
  modalContent.classList.add('slide-up');

  // timeout is set to the same amount as slide-up amount
  setTimeout(() => {
    document.querySelector('.js-modal').classList.remove('is-active');
    modalContent.classList.remove('slide-up');
    // reset is-fullscreen
    modalContent.classList.remove('is-fullscreen');
  }, 400);
}

function showBackgroundClickToCloseModal() {
  const imageModal = document.querySelector('.js-image-modal');
  if (!imageModal.classList.contains('is-active')) {
    imageModal.classList.add('is-active');
  }
}

function hideDropdowns() {
  const dropdowns = document.getElementsByClassName('js-dropdown');
  Array.prototype.forEach.call(dropdowns, (dropdown) => {
    dropdown.classList.remove('is-active');
  });
}

function bindNavbarBurgerToggle() {
  const navbarBurger = document.querySelector('.js-navbar-burger');
  navbarBurger.addEventListener('click', (e) => {
    e.currentTarget.classList.toggle('is-active');
    document.querySelector('.js-navbar-menu').classList.toggle('is-active');
  });
}

function bindSlideUp() {
  if (document.querySelector('.js-slide-up')) {
    const slideUpHandler = (e) => {
      e.currentTarget.parentNode.classList.add('animation-slide-up');
    };
    document
      .querySelector('.js-slide-up')
      .addEventListener('click', slideUpHandler);
  }
}

function bindDatePicker(className, options = {}) {
  const enableRange = [
    {
      from: new Date(),
      to: new Date().setDate(356),
    },
  ];

  Array.prototype.forEach.call(document.querySelectorAll(className), (inputField) => {
    // enable past dates if they are in the input field
    if (inputField.value) {
      enableRange.push(new Date(inputField.value));
    }
  });

  const flatpickrOptions = {
    locale: Japanese,
    altInput: true,
    altFormat: 'Y\\年m\\月d\\日',
    dateFormat: 'Y-m-d',
  };

  if (!options.allRange) {
    flatpickrOptions.enable = enableRange;
  }

  flatpickr(className, flatpickrOptions);
}

function bindDatePickerClear(className) {
  const clearDateButtons = document.querySelectorAll(className);
  Array.prototype.forEach.call(clearDateButtons, (clearDateButton) => {
    clearDateButton.addEventListener('click', (e) => {
      e.currentTarget.nextElementSibling._flatpickr.clear();
    });
  });
}

function bindDatePickerInline(className) {
  const enableRange = [
    {
      from: new Date(),
      to: new Date().setDate(356),
    },
  ];

  flatpickr(className, {
    enable: enableRange,
    locale: Japanese,
    altInput: true,
    altFormat: 'Y\\年m\\月d\\日',
    dateFormat: 'Y-m-d',
    inline: true,
    onChange: (selectedDates, _dateStr, instance) => {
      const selectedDate = selectedDates[0];
      const formattedDate = instance.formatDate(selectedDate, 'Y\\年m\\月d\\日（D）19:00');
      document.querySelector(
        '.js-poll-option-text',
      ).value += `${formattedDate}\n`;
    },
  });
}

function bindTagging() {
  Array.prototype.forEach.call(
    document.querySelectorAll('input[type="tags"]'),
    tagsInput,
  );
}

function bindTaskerEarning(className) {
  document.querySelector(className).addEventListener('input', (e) => {
    const basePrice = e.currentTarget.value || 0;

    document.querySelector('.js-tasker-earning').textContent = `£${basePrice}`;
  });
}

function bindOrderLineItemTotal(className) {
  const unitPriceSelections = document.querySelectorAll(className);
  Array.prototype.forEach.call(
    unitPriceSelections,
    (unitPriceSelection) => {
      unitPriceSelection.addEventListener('change', (_e) => {
        const orderLineItemTotal = _calculateOrderLineItemTotal(unitPriceSelections);

        document.querySelector('.js-order-line-item-total').textContent = `£${orderLineItemTotal}`;
      });
    },
  );
}

function _calculateOrderLineItemTotal(unitPriceSelections) {
  let orderLineItemTotal = 0;
  Array.prototype.forEach.call(unitPriceSelections, (selection) => {
    const selectionQuantity = parseInt(selection.value, 10);
    const selectionUnitPrice = parseInt(selection.dataset.unitPrice, 10);

    if (!isNaN(selectionQuantity)) {
      orderLineItemTotal += (selectionUnitPrice * selectionQuantity);
    }
  });

  return orderLineItemTotal / 100;
}

function bindHoverOnMapMarker(map, cssSelector) {
  Array.prototype.forEach.call(
    document.querySelectorAll(cssSelector),
    (taskCard) => {
      taskCard.addEventListener('mouseenter', (e) => {
        const taskCardId = e.currentTarget.getAttribute('data-id');
        map.setFocusOnMarker(taskCardId);
      });
    },
  );
}

function bindModalClose() {
  document
    .querySelector('.js-modal-close')
    .addEventListener('click', hideModal);
}

function bindImageModalClose() {
  const closeTargets = document.querySelectorAll(
    '.js-image-modal-close, .js-image-modal-background',
  );

  Array.prototype.forEach.call(closeTargets, (closeTarget) => {
    closeTarget.addEventListener('click', (e) => {
      e.currentTarget.parentNode.classList.remove('is-active');
    });
  });
}

function bindHeaderDropdowns() {
  const dropdownLinks = document.getElementsByClassName(
    'js-header-dropdown-link',
  );

  Array.prototype.forEach.call(dropdownLinks, (dropdownLink) => {
    dropdownLink.addEventListener('click', (e) => {
      e.currentTarget.parentNode.classList.toggle('is-active');
    });
  });
}

function bindFilterDropdowns() {
  const dropdownLinks = document.getElementsByClassName(
    'js-filter-dropdown-link',
  );
  Array.prototype.forEach.call(dropdownLinks, (dropdownLink) => {
    dropdownLink.addEventListener('click', (e) => {
      const isOpen = e.currentTarget.parentNode.classList.contains('is-active');
      hideDropdowns();
      if (!isOpen) {
        e.currentTarget.parentNode.classList.add('is-active');
      }
    });
  });
}

function bindToggleFilterOnTouch() {
  Utility.toggleElement(
    '.js-touch-filter-trigger',
    '.js-touch-filter-trigger-target',
    'is-hidden-touch',
  );
}

function setFilterLocationLabel(value) {
  const filterLocationLabel = document.getElementById(
    'js-gloc-filter-location-label',
  );

  filterLocationLabel.textContent = value;
}

function bindFilterRemoteChoice() {
  if (!document.querySelector('.js-task-remote-selection')) {
    return;
  }

  const taskRemoteChoices = document.querySelectorAll(
    '.js-task-remote-selection .button',
  );
  const taskRemoteInput = document.querySelector('.js-task-remote-choice');
  const filterLocationInput = document.getElementById(
    'js-gloc-filter-location-input',
  );

  Array.prototype.forEach.call(taskRemoteChoices, (taskRemoteChoice) => {
    taskRemoteChoice.addEventListener('click', (e) => {
      const target = e.currentTarget;

      // update selected choice
      Array.prototype.forEach.call(taskRemoteChoices, (choice) => {
        choice.classList.remove('task-remote-choice');
      });
      target.classList.add('task-remote-choice');

      // update remote input
      const remoteChoice = target.getAttribute('data-remote-choice');
      taskRemoteInput.value = remoteChoice;

      // disable or enable location input
      // update location label
      if (remoteChoice === '1') {
        filterLocationInput.setAttribute('disabled', '');
        setFilterLocationLabel(REMOTE_ONLY_LABEL);
      } else {
        filterLocationInput.removeAttribute('disabled');
        setFilterLocationLabel(filterLocationInput.value);
      }
    });
  });
}

function bindMobileStatusSelectNavigation(
  selectCssSelector,
  linkContainerCssSelector,
) {
  const selectElement = document.querySelector(selectCssSelector);
  if (!selectElement) {
    return;
  }

  selectElement.addEventListener('change', (e) => {
    const selectedElementLink = Utility.getElementByTextContent(
      `${linkContainerCssSelector} a`,
      e.currentTarget.value,
    );
    if (selectedElementLink) {
      selectedElementLink.click();
    }
  });
}

function bindAndReturnMapView() {
  const mapContainer = document.getElementById('js-map-view');
  if (mapContainer) {
    // sets center
    const lat = mapContainer.getAttribute('data-lat');
    const lng = mapContainer.getAttribute('data-lng');
    let center;
    if (lat && lng) {
      center = [lat, lng];
    }

    const mapId = mapContainer.getAttribute('data-map-id');
    const options = { mapId };
    // draws a map and sets markers
    const taskBoundMap = GmapApi(mapContainer, options)
      .drawMap(center)
      .setMarkers('js-map-card');

    bindHoverOnMapMarker(taskBoundMap, '.js-map-card');

    // reload tasks on map area changed
    if (mapContainer.hasAttribute('data-draggable')) {
      taskBoundMap
        .addDraggableIndication('マップを動かしながら検索')
        .onMapAreaChanged((position) => {
          // set bounding box values and apply the filter
          document.getElementById('js-gloc-filter-lat').value = position.centerLat;
          document.getElementById('js-gloc-filter-lng').value = position.centerLng;
          document.getElementById('js-bounding-box-filter-swlat').value = position.southWestLat;
          document.getElementById('js-bounding-box-filter-swlng').value = position.southWestLng;
          document.getElementById('js-bounding-box-filter-nelat').value = position.northEastLat;
          document.getElementById('js-bounding-box-filter-nelng').value = position.northEastLng;
          document.querySelector('.js-filter-apply').click();

          // reset bounding box so that the other filers
          // do not interfer with the bounding box filter
          document.getElementById('js-bounding-box-filter-swlat').value = '';
          document.getElementById('js-bounding-box-filter-swlng').value = '';
          document.getElementById('js-bounding-box-filter-nelat').value = '';
          document.getElementById('js-bounding-box-filter-nelng').value = '';
        });
    }

    return taskBoundMap;
  }

  return null;
}

function bindLoadMoreList() {
  const taskList = document.querySelector('.js-load-more-list');

  if (!taskList) {
    return;
  }

  taskList.addEventListener('scroll', (e) => {
    const target = e.currentTarget;
    const loadMoreUrl = target.getAttribute('data-load-more-url');
    if (!loadMoreUrl) {
      return;
    }

    // give a buffer to make sure the loading starts towards the end of the list
    const approximateCardHeight = 200;
    if (target.scrollTop + target.clientHeight + approximateCardHeight > target.scrollHeight) {
      if (!gTaskLoading) {
        gTaskLoading = true;
        Utility.railsAjax('GET', loadMoreUrl, 'script', {
          complete: (_xhr, status) => {
            if (status === 'OK') {
              gTaskLoading = false;
            }
          },
        });
      }
    }
  });
}

function bindToggleMapOnTouch() {
  Utility.toggleElement(
    '.js-map-toggle',
    '.js-map-toggle-target',
    'is-hidden-touch',
    () => {
      Array.prototype.forEach.call(
        document.querySelectorAll('.js-map-toggle-label'),
        (mapToggleLabel) => {
          mapToggleLabel.classList.toggle('is-hidden');
        },
      );
    },
  );
}

function _bindMessagePreviewImage(formSelector) {
  const messageForm = document.querySelector(formSelector);
  const previewImage = messageForm.querySelector('.js-preview');
  const openMessageAvatarFileTarget = messageForm.querySelector('.js-open-message-avatar-file-target');

  // open a file explorer
  messageForm.querySelector('.js-open-message-avatar-file').addEventListener('click', () => {
    openMessageAvatarFileTarget.removeAttribute('disabled');
    openMessageAvatarFileTarget.click();
  });

  // show preview
  openMessageAvatarFileTarget.addEventListener('change', (e) => {
    const file = e.currentTarget.files[0];
    // IE11 triggers onchange event on delete preview which results in file being null
    if (file) {
      if (file.type === 'application/pdf') {
        previewImage.src = previewImage.getAttribute('data-pdf-sprite');
        // sprite class
        previewImage.classList.add('pdf');
        previewImage.parentNode.classList.remove('is-hidden');
      } else { // case for images
        const reader = new FileReader();
        reader.onload = (e) => {
          previewImage.src = e.currentTarget.result;
          previewImage.parentNode.classList.remove('is-hidden');
        };
        reader.readAsDataURL(file);
      }
    }
  });

  // delete preview
  messageForm.querySelector('.js-reset-file').addEventListener('click', () => {
    openMessageAvatarFileTarget.setAttribute('disabled', '');
    // used for removing an existing message avatar on edit/update
    const removeMessageAvatarInput = messageForm.querySelector('.js-remove-message-avatar-input');
    if (removeMessageAvatarInput) {
      removeMessageAvatarInput.checked = true;
    }

    previewImage.src = previewImage.getAttribute('data-pdf-sprite');
    previewImage.classList.remove('pdf');
    previewImage.parentNode.classList.add('is-hidden');
  });
}

function bindMessageFormFeature(cssSelector) {
  if (document.querySelector(cssSelector)) {
    _bindMessagePreviewImage(cssSelector);
    Utility.bindAutoExpandTextarea(`${cssSelector} .js-auto-expand`);
  }
}

function bindSortable(listSelector) {
  const listElement = document.querySelector(listSelector);
  if (!listElement) {
    return;
  }

  const currentPage = parseInt(listElement.dataset.currentPage, 10);
  const perPage = parseInt(listElement.dataset.perPage, 10);

  Sortable.create(listElement, {
    ghostClass: 'sortable-ghost-background',
    // impossible to use sortable unless intended on touch devices
    delay: 4000,
    delayOnTouchOnly: true,
    onUpdate(e) {
      const { url } = e.item.dataset;
      let newIndex = e.newDraggableIndex;
      if (currentPage && perPage) {
        newIndex = ((currentPage - 1) * perPage) + e.newDraggableIndex;
      }

      Utility.railsAjax('PATCH', url, 'script', {
        data: `new_index=${newIndex}`,
      });
    },
  });
}

function bindMerchandiseAddonCreateOrDestroy() {
  const serviceAddonCheckboxes = document.querySelectorAll('.js-merchandise-addon-checkboxes');
  Array.prototype.forEach.call(serviceAddonCheckboxes, (checkbox) => {
    checkbox.addEventListener('change', (e) => {
      const { url } = e.currentTarget.dataset;
      Utility.railsAjax('POST', url, 'script');
    });
  });
}

function bindStripeConnectBusinessTypeToggle() {
  const stripeConnectBusinessToggle = document.querySelector('.js-stripe-connect-business-type-toggle');
  if (!stripeConnectBusinessToggle) {
    return;
  }

  // hide all business type fields
  const hideBusinessTypeFields = function () {
    const stripeConnectBusinessToggleTargets = document.querySelectorAll('.js-stripe-connect-business-type-toggle-target');

    Array.prototype.forEach.call(stripeConnectBusinessToggleTargets, (target) => {
      target.classList.add('is-hidden');
    });
  };

  // show selected business type fields
  const showBusinessType = function (businessType) {
    document.querySelector(`.js-stripe-connect-business-type-toggle-target[data="${businessType}"]`).classList.remove('is-hidden');
  };

  hideBusinessTypeFields();
  showBusinessType(stripeConnectBusinessToggle.value);

  stripeConnectBusinessToggle.addEventListener('change', (e) => {
    hideBusinessTypeFields();
    showBusinessType(e.currentTarget.value);
  });
}

const Neconote = {
  showModal,
  hideModal,
  showBackgroundClickToCloseModal,
  hideDropdowns,
  bindNavbarBurgerToggle,
  bindSlideUp,
  bindDatePicker,
  bindDatePickerClear,
  bindDatePickerInline,
  bindTagging,
  bindTaskerEarning,
  bindOrderLineItemTotal,
  bindHoverOnMapMarker,
  bindModalClose,
  bindImageModalClose,
  bindHeaderDropdowns,
  bindFilterDropdowns,
  bindToggleFilterOnTouch,
  setFilterLocationLabel,
  bindFilterRemoteChoice,
  bindMobileStatusSelectNavigation,
  bindAndReturnMapView,
  bindLoadMoreList,
  bindToggleMapOnTouch,
  bindMessageFormFeature,
  bindSortable,
  bindMerchandiseAddonCreateOrDestroy,
  bindStripeConnectBusinessTypeToggle,
};

export default Neconote;
