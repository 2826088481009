import noUiSlider from 'nouislider';

// cssId of an element with
// min, max, from, to (optional), minFieldId and maxFieldId as data attributes
// prefixFormat formats the tooltip with a prefix e.g. '£ ' for '£ 15'
export function bindSlider(cssId, prefixFormat) {
  const slider = document.getElementById(cssId);
  if (!slider) {
    return;
  }

  const sliderMin = slider.getAttribute('data-min');
  const sliderMax = slider.getAttribute('data-max');
  const sliderFrom = slider.getAttribute('data-from');
  const sliderTo = slider.getAttribute('data-to');

  noUiSlider.create(slider, {
    start: sliderTo ? [sliderFrom, sliderTo] : [sliderFrom],
    connect: sliderTo ? true : [true, false],
    step: 5,
    margin: 5,
    format: {
      to(value) {
        let toFormat = value;

        if (prefixFormat) {
          toFormat = prefixFormat + parseFloat(value).toFixed();
        }

        return toFormat;
      },
      from(value) {
        const fromFormat = value;

        if (prefixFormat) {
          fromFormat.replace(prefixFormat, '');
        }

        return fromFormat;
      },
    },
    tooltips: true,
    range: {
      min: parseInt(sliderMin, 10),
      max: parseInt(sliderMax, 10),
    },
  });

  /* https://refreshless.com/nouislider/events-callbacks/ */
  slider.noUiSlider.on('change', (_values, _handle, unencoded) => {
    let minValue;
    let maxValue;
    if (sliderTo) {
      const minFieldId = slider.getAttribute('data-minfieldid');
      const maxFieldId = slider.getAttribute('data-maxfieldid');
      minValue = parseFloat(unencoded[0]).toFixed();
      maxValue = parseFloat(unencoded[1]).toFixed();
      document.getElementById(minFieldId).value = minValue;
      document.getElementById(maxFieldId).value = maxValue;
    } else {
      const maxFieldId = slider.getAttribute('data-maxfieldid');
      maxValue = parseFloat(unencoded[0]).toFixed();
      document.getElementById(maxFieldId).value = maxValue;
    }
  });
}
