import Rails from '@rails/ujs';
import 'url-polyfill';
import ClipboardJS from 'clipboard';
import Utility from '../src/js/utility';
import Neconote from '../src/js/neconote';
import { bindReviewStar } from '../src/js/review';
import PrizeDraw from '../src/js/prize';
import { bindSlider } from '../src/js/slider';
import { bindAcceptCookiePrivacy, bindShowPopup } from '../src/js/cookie';
import PaymentApi from '../src/js/payment-api';
import GplaceApi from '../src/js/gplace-api';
import Vote from '../src/js/vote';

window.Utility = Utility;
window.Neconote = Neconote;
window.GplaceApi = GplaceApi;
window.PaymentApi = PaymentApi;
window.Vote = Vote;
Rails.start();

// intended for tasks/index.html.haml
// called on go back after some sort of a JS filter
window.onpopstate = () => {
  window.location.reload();
};

document.addEventListener('DOMContentLoaded', () => {
  Utility.bindOnChangeClick();
  Utility.bindOnClickClick();
  Utility.bindClick('.js-onload-click');
  Utility.bindOnClickScrollTo();

  Neconote.bindModalClose();
  Neconote.bindImageModalClose();

  // _notification.html.haml
  Neconote.bindSlideUp();

  // _header.html.haml
  Neconote.bindNavbarBurgerToggle();
  Neconote.bindHeaderDropdowns();

  // _filter.html.haml
  Neconote.bindFilterDropdowns();
  Neconote.bindToggleFilterOnTouch();
  bindSlider('priceSlider', '£');
  Neconote.bindFilterRemoteChoice();

  // reviews/_form.html.haml
  bindReviewStar();

  // _cookie_privacy.html.haml
  bindAcceptCookiePrivacy();

  // homes/index.html.haml
  bindShowPopup();

  // tasker_tasks/index.html.haml
  // poster_tasks/index.html.haml
  // poster_rooms/index.html.haml
  Neconote.bindMobileStatusSelectNavigation(
    '.js-mobile-status-select',
    '.js-mobile-status-select-container',
  );

  // shared/_tasks.html.haml
  // shared/_rooms.html.haml
  window.gMap = Neconote.bindAndReturnMapView();
  Neconote.bindLoadMoreList();
  Neconote.bindToggleMapOnTouch();

  // bind calendar to all .js-datepicker elements
  if (document.querySelector('.js-datepicker')) {
    Neconote.bindDatePicker('.js-datepicker');
  }
  // bind calendar to all .js-clear-date elements
  if (document.querySelector('.js-clear-date')) {
    Neconote.bindDatePickerClear('.js-clear-date');
  }
  // bind 'all range' calendar to all .js-datepicker-all-range elements
  if (document.querySelector('.js-datepicker-all-range')) {
    Neconote.bindDatePicker('.js-datepicker-all-range', { allRange: true });
  }

  // bind calendar to all .js-datepicker elements
  if (document.querySelector('.js-datepicker-inline')) {
    Neconote.bindDatePickerInline('.js-datepicker-inline');
  }

  // shared/_autocomplete_location_input.html.haml
  [
    'js-gloc-filter-location-input',
    'js-gloc-task-location-input',
    'js-gloc-room-location-input',
    'js-gloc-merchandise-location-input',
    'js-gloc-user-location-input',
    'js-gloc-kyujin-location-input',
  ].forEach((glocLocationInputId) => {
    GplaceApi(glocLocationInputId).enable();
  });

  // messages/_form.html.haml
  Neconote.bindMessageFormFeature('.js-message-form[data-id="new"]');

  // tasks/_form.html.haml
  if (document.querySelector('.js-task-form')) {
    Utility.bindOnCheckHide(
      '.js-task-remote-checkbox',
      '#js-gloc-task-location-input',
    );
  }

  // events/_form.html.haml
  if (document.querySelector('.js-merchandise-form')) {
    Utility.bindOnCheckHide(
      '.js-merchandise-is-free-checkbox',
      '.js-merchandise-price',
    );
  }

  // merchandises/index.html.haml
  Neconote.bindMerchandiseAddonCreateOrDestroy();
  Neconote.bindSortable('.js-sortable-list');

  // stripe_connects/_form.html.haml
  Neconote.bindStripeConnectBusinessTypeToggle();

  const clipboard = new ClipboardJS('.js-clipboard');
  clipboard.on('success', (e) => {
    e.clearSelection();
  });

  if (document.getElementById('js-prize-wheel')) {
    window.PrizeDraw = new PrizeDraw();
  }
});
