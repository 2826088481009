function resetAllStar() {
  const emptyReviews = document.querySelectorAll('.js-review-star > .empty-star');
  Array.prototype.forEach.call(emptyReviews, (emptyReview) => {
    emptyReview.classList.remove('is-hidden');
  });

  const fullReviews = document.querySelectorAll('.js-review-star > .full-star');
  Array.prototype.forEach.call(fullReviews, (fullReview) => {
    fullReview.classList.add('is-hidden');
  });
}

function updateAllStar(starValue) {
  for (let i = 1; i <= starValue; i += 1) {
    document
      .querySelector(`.js-review-star[data-value="${i}"] > .empty-star`).classList.add('is-hidden');
    document
      .querySelector(`.js-review-star[data-value="${i}"] > .full-star`).classList.remove('is-hidden');
  }
}

function getReviewStarValue() {
  return document.querySelector('.js-review-star-value').getAttribute('value');
}

function setReviewStarValue(value) {
  document.querySelector('.js-review-star-value').setAttribute('value', value);
}

function initAllStar() {
  resetAllStar();
  updateAllStar(getReviewStarValue());
}

export function bindReviewStar() {
  if (!document.querySelector('.js-review-star')) {
    return;
  }

  initAllStar();

  const reviewStars = document.querySelectorAll('.js-review-star');
  Array.prototype.forEach.call(reviewStars, (reviewStar) => {
    reviewStar.addEventListener('mouseover', (e) => {
      const selectedValue = e.currentTarget.getAttribute('data-value');
      // update UI
      resetAllStar();
      updateAllStar(selectedValue);
    });

    reviewStar.addEventListener('click', (e) => {
      const selectedValue = e.currentTarget.getAttribute('data-value');
      // update input value
      setReviewStarValue(selectedValue);
    });

    // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006511-SW5
    // for ios 'click' is 'conditional' and it seems that
    // it has to be a double-click when it is bound with 'mouseover'
    reviewStar.addEventListener('touchstart', (e) => {
      const selectedValue = e.currentTarget.getAttribute('data-value');
      // update input value
      setReviewStarValue(selectedValue);
    });
  });

  const reviewStarContainer = document.querySelector('.js-review-star-container');
  reviewStarContainer.addEventListener('mouseleave', () => {
    // update UI
    resetAllStar();
    updateAllStar(getReviewStarValue());
  });
}
