// https://github.com/tomchentw/react-google-maps/issues/434
/* global google */
import Utility from './utility';

// default to 'London, UK'
const DEFAULT_CENTER = ['51.5073509', '-0.12775829999998223'];

const getLatLngFromUrl = () => {
  const url = new URL(window.location.href);
  const center = {
    lat: url.searchParams.get('lat'),
    lng: url.searchParams.get('lng'),
  };

  return (
    center.lat && center.lng && new google.maps.LatLng(center.lat, center.lng)
  );
};

const getCenterLatLng = (center) => {
  if (center) {
    return new google.maps.LatLng(...center);
  }

  return getLatLngFromUrl() || new google.maps.LatLng(...DEFAULT_CENTER);
};

const getSvgIcon = (options = {}) => {
  const svgNS = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(svgNS, 'svg');
  svg.setAttribute('height', '47');
  svg.setAttribute('width', '41');
  svg.setAttribute('xmlns', svgNS);

  const path = document.createElementNS(svgNS, 'path');
  path.setAttribute('d', 'M20.03662 0a20.04 20.04 0 0 0-4.64431 39.53354c1.00741 1.58225 2.18556 3.41493 3.53446 5.55495a1.30337 1.30337 0 0 0 1.76438.461 1.37165 1.37165 0 0 0 .461-.461c1.3489-2.14 2.52136-3.98408 3.53446-5.55495A20.05135 20.05135 0 0 0 20.03662 0zm4.09792 24.95177l-.16506.12522c-4.15483 3.15881-7.72344 1.94082-7.96817-2.70349s3.18727-7.25673 7.60961-5.80539 4.64999 5.21916.52362 8.38366zm-8.20722-12.71493a2.38476 2.38476 0 1 1 2.38476 2.38476 2.39045 2.39045 0 0 1-2.38476-2.38476zm8.96989 1.86116a2.38475 2.38475 0 1 1 .02862.00017q-.01431-.00002-.02862-.00017zm4.12068 3.75073a3.74814 3.74814 0 0 1-.88788-3.58 4.3882 4.3882 0 0 0-.43825-4.90612 4.1207 4.1207 0 0 0-5.76555-.77975 4.30307 4.30307 0 0 0-.7399.76836 4.12069 4.12069 0 0 0-7.02338 2.74333c-.0626.17644-.08537.296-.09675.313a4.12068 4.12068 0 0 0-4.76952 5.91352 3.70529 3.70529 0 0 0-.62608.387 4.12637 4.12637 0 0 0-.77974 5.76554 4.189 4.189 0 0 0 4.65569 1.63917c2.17418-.43256 3.50031 2.37907 4.5191 3.82473 1.35459 1.93513 2.84578 4.21175 4.25159 6.3973-.43256 0-.85942.05123-1.292.05123a16.56812 16.56812 0 1 1 15.21352-9.95453c-2.02048-2.98231-4.27434-6.15251-6.22085-8.5828zm-15.83959.77975a2.38192 2.38192 0 1 1 .00569 0zm.296 3.62552a2.38477 2.38477 0 1 1-2.38477-2.38476 2.38476 2.38476 0 0 1 2.37904 2.38476z');
  path.setAttribute('fill-opacity', '1');
  path.setAttribute('stroke-width', '1');
  path.setAttribute('stroke', options.color || '#000');
  path.setAttribute('fill', options.color || '#000');

  svg.appendChild(path);

  return svg;
};

const getPriceIcon = (price) => {
  const priceTag = document.createElement('div');
  priceTag.className = 'gm-marker-price';
  priceTag.textContent = price;

  return priceTag;
};

const randomOffsetPosition = (lat, lng, maxOffsetMeters = 500) => {
  const metersPerDegreeLat = 111320;
  const metersPerDegreeLng = 111320 * Math.cos(lat * (Math.PI / 180));

  const maxOffsetLat = maxOffsetMeters / metersPerDegreeLat;
  const maxOffsetLng = maxOffsetMeters / metersPerDegreeLng;

  const offsetLat = (Math.random() * (2 * maxOffsetLat)) - maxOffsetLat;
  const offsetLng = (Math.random() * (2 * maxOffsetLng)) - maxOffsetLng;

  const newLat = lat + offsetLat;
  const newLng = lng + offsetLng;

  return { newLat, newLng };
}

// Map IDs: https://console.cloud.google.com/google/maps-apis/studio/maps?walkthrough_id=maps--maps_styling&project=mineral-aegis-231013
const googleMapsMapOption = {
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: true,
  zoom: 12,
  scrollwheel: false,
  scaleControl: false
};

function Init(mapContainer, options) {
  this.mapContainer = mapContainer;
  this.map = null;
  this.lastInfoWindow = null;
  this.lastFocusedMarker = null;
  this.draggable = false;
  this.storedMarkers = [];
  this.options = options;
}

Init.prototype = {
  drawMap(center) {
    const mapCenter = getCenterLatLng(center);
    const googleMapsMap = new google.maps.Map(this.mapContainer, {
      center: mapCenter,
      mapId: (this.options.mapId || '72347a97c59d0c96'),
      ...googleMapsMapOption,
    });

    this.map = googleMapsMap;

    return this;
  },
  // className of an element with lat, lng and info-window-url as data attributes
  // it only adds new markers and removes non-existing markers from the array
  setMarkers(className) {
    const markedElements = document.getElementsByClassName(className);
    const markers = [];

    Array.prototype.forEach.call(markedElements, (markedElement) => {
      const id = markedElement.getAttribute('data-id');
      const latlng = new google.maps.LatLng(
        markedElement.getAttribute('data-lat'),
        markedElement.getAttribute('data-lng'),
      );
      const infoWindowUrl = markedElement.getAttribute('data-info-window-url');
      const price = markedElement.getAttribute('data-price');
      markers.push({
        id, latlng, infoWindowUrl, price,
      });
    });

    this.storedMarkers.forEach((storedMarker) => {
      const found = markers.some((marker) => marker.id === storedMarker.id);

      if (!found) {
        this.removeMarker(storedMarker.id);
      }
    });

    markers.forEach((marker) => {
      const found = this.storedMarkers.some(
        (storedMarker) => storedMarker.id === marker.id,
      );

      if (!found) {
        this.addMarker(marker.id, marker.latlng, marker.infoWindowUrl, marker.price);
      }
    });

    return this;
  },
  // create a marker, add marker to the map and the array
  addMarker(id, latlng, infoWindowUrl, price) {
    let markerPosition = latlng;
    const markerLat = markerPosition.lat();
    const markerLng = markerPosition.lng();

    // offset the markers in the same location
    const isOverlapping = this.storedMarkers.some((storedMarker) => {
      const existingPosition = storedMarker.marker.position;

      return (
        (markerLat.toFixed(6) === existingPosition.lat.toFixed(6)) &&
        (markerLng.toFixed(6) === existingPosition.lng.toFixed(6))
      );
    });

    if (isOverlapping) {
      const { newLat, newLng } = randomOffsetPosition(markerLat, markerLng);

      markerPosition = new google.maps.LatLng(newLat, newLng);
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: markerPosition,
      content: price ? getPriceIcon(price) : getSvgIcon()
    });

    this.storedMarkers.push({ id, marker });

    marker.addListener('click', () => {
      Utility.railsAjax('GET', infoWindowUrl, 'html', {
        success: (_response, _status, xhr) => {
          if (this.lastInfoWindow) {
            this.lastInfoWindow.close();
          }

          const infoWindow = new google.maps.InfoWindow({
            content: xhr.response,
            maxWidth: 500,
          });

          infoWindow.open(this.map, marker);

          this.lastInfoWindow = infoWindow;
        },
      });
    });

    return this;
  },
  // remove a marker from the map and the array
  removeMarker(id) {
    const removingStoredMarker = this.storedMarkers.find(
      (storedMarker) => storedMarker.id === id,
    );
    if (removingStoredMarker) {
      removingStoredMarker.marker.setMap(null);

      this.storedMarkers = this.storedMarkers.filter(
        (storedMarker) => storedMarker.id !== removingStoredMarker.id,
      );
    }

    return this;
  },
  onMapAreaChanged(callback) {
    // https://developers.google.com/maps/documentation/javascript/reference/map
    // https://developers.google.com/maps/documentation/javascript/events
    this.map.addListener('dragend', () => {
      if (this.draggable) {
        callback(this.getMapPosition());
      }
    });

    this.map.addListener('zoom_changed', () => {
      if (this.draggable) {
        callback(this.getMapPosition());
      }
    });

    return this;
  },
  addDraggableIndication(label) {
    // https://developers.google.com/maps/documentation/javascript/controls
    const controlContainer = document.createElement('div');
    const controlCheckboxContainer = document.createElement('div');
    const controlCheckbox = document.createElement('input');
    const controlCheckboxLebel = document.createElement('label');
    controlContainer.className = 'gm-draggable-indication-ui';

    controlCheckboxContainer.className = 'field';
    controlCheckbox.setAttribute('type', 'checkbox');
    controlCheckbox.id = 'draggableIndicationCheckbox';
    controlCheckbox.className = 'is-checkradio';
    controlCheckboxLebel.setAttribute('for', 'draggableIndicationCheckbox');
    controlCheckboxLebel.textContent = label;
    controlCheckboxLebel.className = 'margin-right-0';

    controlContainer.appendChild(controlCheckboxContainer);
    controlCheckboxContainer.appendChild(controlCheckbox);
    controlCheckboxContainer.appendChild(controlCheckboxLebel);

    controlCheckbox.addEventListener('change', (e) => {
      this.draggable = e.currentTarget.checked;
    });

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      controlContainer,
    );

    return this;
  },
  panToCenter() {
    this.map.panTo(getCenterLatLng());

    return this;
  },
  setFocusOnMarker(id) {
    const storedMarker = this.storedMarkers.find((e) => e.id === id);

    if (storedMarker) {
      if (this.lastFocusedMarker) {
        if (this.lastFocusedMarker.content.querySelector('path')) {
          this.lastFocusedMarker.content = getSvgIcon();
        } else {
          this.lastFocusedMarker.content.className = 'gm-marker-price';
        }
      }

      if (storedMarker.marker.content.querySelector('path')?.getAttribute('fill') === '#000') {
        storedMarker.marker.content = getSvgIcon({ color: '#009a8b' });
      } else if (storedMarker.marker.content.className !== 'gm-marker-price-hovered') {
        storedMarker.marker.content.className = 'gm-marker-price-hovered';
      }

      this.lastFocusedMarker = storedMarker.marker;
    }

    return this;
  },
  getMapPosition() {
    const bounds = this.map.getBounds();

    const position = {
      centerLat: bounds.getCenter().lat(),
      centerLng: bounds.getCenter().lng(),
      southWestLat: bounds.getSouthWest().lat(),
      southWestLng: bounds.getSouthWest().lng(),
      northEastLat: bounds.getNorthEast().lat(),
      northEastLng: bounds.getNorthEast().lng(),
    };

    return position;
  },
};

export function bindGoogleMapsMap(mapContainer, options) {
  return new Init(mapContainer, options);
}

export default bindGoogleMapsMap;
